
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label prop="id">
          <el-input v-model="formModel.id" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label prop="createBy">
          <el-input v-model="formModel.createBy" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label prop="createTime">
          <el-input v-model="formModel.createTime" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label prop="updateBy">
          <el-input v-model="formModel.updateBy" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label prop="updateTime">
          <el-input v-model="formModel.updateTime" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="是否删除" prop="delFlag">
          <el-input v-model="formModel.delFlag" placeholder="请输入是否删除" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="提交人ID" prop="ownerId">
          <el-input v-model="formModel.ownerId" placeholder="请输入提交人ID" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="所在小区" prop="community">
          <el-input v-model="formModel.community" placeholder="请输入所在小区" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="提交内容" prop="content">
          <el-input v-model="formModel.content" placeholder="请输入提交内容" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="提交状态" prop="status">
          <el-input v-model="formModel.status" placeholder="请输入提交状态" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="回复内容" prop="returnContent">
          <el-input v-model="formModel.returnContent" placeholder="请输入回复内容" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="回复时间" prop="returnTime">
          <el-input v-model="formModel.returnTime" placeholder="请输入回复时间" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="状态/1投诉/2报修" prop="type">
          <el-input v-model="formModel.type" placeholder="请输入状态/1投诉/2报修" style="width:300px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import informationInfoApi from "@/api/base/informationInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        id: [{ required: true, message: "不能为空", trigger: "blur" }],
        createBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        createTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        delFlag: [
          { required: true, message: "是否删除不能为空", trigger: "blur" }
        ],
        ownerId: [
          { required: true, message: "提交人ID不能为空", trigger: "blur" }
        ],
        community: [
          { required: true, message: "所在小区不能为空", trigger: "blur" }
        ],
        content: [
          { required: true, message: "提交内容不能为空", trigger: "blur" }
        ],
        status: [
          { required: true, message: "提交状态不能为空", trigger: "blur" }
        ],
        returnContent: [
          { required: true, message: "回复内容不能为空", trigger: "blur" }
        ],
        returnTime: [
          { required: true, message: "回复时间不能为空", trigger: "blur" }
        ],
        type: [
          {
            required: true,
            message: "状态/1投诉/2报修不能为空",
            trigger: "blur"
          }
        ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {}
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return informationInfoApi.add(self.formModel);
            } else {
              return informationInfoApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              this.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              this.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;

    (function() {
      if (self.businessKey.length == 0) {
        return informationInfoApi.create();
      } else {
        return informationInfoApi.edit(self.menuId);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>